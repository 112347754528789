.header-menu--16kAj {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 10%); }
  @media (max-width: 989px) {
    .header-menu--16kAj {
      position: relative; }
      .header-menu--16kAj::before, .header-menu--16kAj::after {
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 1;
        width: 20px;
        content: '';
        pointer-events: none; }
      .header-menu--16kAj::before {
        left: 0;
        background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.85) 0%, rgba(255, 255, 255, 0) 100%); }
      .header-menu--16kAj::after {
        right: 0;
        background-image: linear-gradient(270deg, rgba(255, 255, 255, 0.85) 0%, rgba(255, 255, 255, 0) 100%); } }
  .header-menu__scroll-container--1-F7W {
    margin-left: auto;
    margin-right: auto; }
    @media (max-width: 989px) {
      .header-menu__scroll-container--1-F7W {
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        -webkit-overflow-scrolling: touch; }
        .header-menu__scroll-container--1-F7W::-webkit-scrollbar {
          display: none; } }
    @media (min-width: 990px) and (max-width: 1279px) {
      .header-menu__scroll-container--1-F7W {
        max-width: 940px; } }
    @media (min-width: 1280px) {
      .header-menu__scroll-container--1-F7W {
        max-width: 1120px; } }
  .header-menu__container--1InZs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: max-content; }
    @media (max-width: 989px) {
      .header-menu__container--1InZs {
        padding-left: 20px;
        padding-right: 20px; } }
  .header-menu__links--2v2lP {
    display: flex; }
    .header-menu__links--2v2lP * {
      flex-shrink: 0; }
